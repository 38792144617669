<template>
  <div>
    <BaseTableWrapper :table-init="tableInit" :remove-padding="isModeHistory">
      <h1 v-if="isModeHistory" class="page-header">My Call History</h1>
      <h1 v-else class="page-header">Calls</h1>
    </BaseTableWrapper>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import calls from '@/services/calls'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { authComputed } from '@/state/helpers'
import { deepClone } from '@/utils/deepClone'
import { DateTime } from 'luxon'
import CallViewDetail from '@/components/CallViewDetail.vue'
import { noFutureDatesPredefined } from '@/utils/predefined'

const callStatusMap = [
  { text: 'Missed', value: 'missed' },
  { text: 'Completed', value: 'completed' },
  { text: 'Returned', value: 'returned' },
  { text: 'In Progress', value: 'in_progress' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Transferred', value: 'transferred' },
  { text: 'Callback Request', value: 'callback_request' },
]
const directionMap = [
  { text: 'Inbound', value: 'Inbound' },
  { text: 'Outbound', value: 'Outbound' },
  { text: 'Callback', value: 'Callback' },
]

const categories = [
  {
    _t_id: 'cfb42fbe',
    text: 'Call Status',
    prop: 'callStatus/key',
    values: deepClone(callStatusMap),
    type: 'text',
    method: 'or',
  },
  {
    _t_id: 'c2883884',
    text: 'Direction',
    prop: 'direction',
    values: deepClone(directionMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Calls',
    }
  },
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    const wrappedBaseActions = actionsWrapper(
      BaseActionsRequiresWrapper,
      'calls',
      [
        'calls-mark-returned',
        'call-returned',
        'call-comments',
        'call-audio',
        'call-download',
      ],
      ''
    )

    const callHistoryActions = actionsWrapper(
      BaseActionsRequiresWrapper,
      'call',
      ['view'],
      ''
    )

    let historyColumns = [
      {
        _t_id: 'f2ca7cb3',
        prop: '/',
        text: 'Actions',
        component: callHistoryActions,
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: 'aa39ea85',
        prop: 'phoneNumber/callerId',
        text: 'Name',
        filter: true,
        sort: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: '63a75ba8',
        prop: 'lead',
        text: 'Customer Name',
        computedText: (item) =>
          item ? `${item?.firstName} ${item?.lastName}` : '',
        filter: true,
        sort: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: 'b0caf1e2',
        prop: 'phoneNumber/phoneNumber',
        text: 'Phone Number',
        sort: true,
        filter: true,
        computedText: (item) =>
          item && item.length >= 12
            ? `(${item.slice(2, 5)}) ${item.slice(5, 8)}-${item.slice(8, 12)}`
            : item,
        filterType: 'eq',

        type: 'string',
      },
      {
        _t_id: 'dfee9810',
        prop: 'createdOn',
        text: 'Call Date/Time',
        sort: true,
        sortProp: 'callBack.entryDate',
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        forceOneLine: true,
        computedText: (item) =>
          DateTime.fromISO(item).toFormat('MM/dd/yyyy hh:mm a'),
        detail: false,
      },
      {
        _t_id: '1431e1da',
        prop: 'callStatus/label',
        text: 'Call Status',
        sort: true,
        type: 'text',
        detail: false,
      },
    ]
    let defaultColumns = [
      {
        _t_id: 'f2ca7cb3',
        prop: '/',
        text: 'Actions',
        component: wrappedBaseActions,
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: 'z9c6c7fc1',
        prop: 'callId',
        text: 'Call Id',
        filter: true,
        sort: true,
        type: 'text',
        filterType: 'eq',
        defaultSort: true,
      },
      {
        _t_id: 'c9c6c7fc1',
        prop: 'callFrom',
        text: 'Customer Phone',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
      },
      {
        _t_id: 'a0f28021',
        prop: 'createdOn',
        text: 'Call Date/Time',
        sort: true,
        sortProp: 'callBack.entryDate',
        filter: true,
        type: 'date',
        method: 'and',
        childMethod: 'and',
        filterType: 'eq',
        predefined: deepClone(noFutureDatesPredefined),
        forceOneLine: true,
        computedText: (item) =>
          DateTime.fromISO(item).toFormat('MM/dd/yyyy hh:mm a'),
        detail: false,
      },
      {
        _t_id: 'da0f28021',
        prop: 'callBack/preferredCallbackNumber',
        text: 'Call Back Number',
        sort: true,
        type: 'text',
        detail: false,
      },
      {
        _t_id: '464151b8',
        prop: 'answeredByName',
        text: 'Answered By',
        sort: true,
        type: 'text',
        detail: false,
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: 'a166d472',
        prop: ['returnedBy/firstName', 'returnedBy/lastName'],
        text: 'Returned By',
        sort: true,
        sortProp: 'returnedBy.lastName',
        type: 'text',
        detail: false,
        filter: true,
        filterType: 'contains',
        filterProp: ['returnedBy/firstName', 'returnedBy/lastName'],
      },
      {
        _t_id: 'a7f5bd20-1a30-47e5-b1fb-40460d8a0434',
        prop: 'queue',
        text: 'Queue',
        sort: true,
        type: 'text',
        detail: false,
        computedText: (item) => (item === 'undefined' ? null : item),
        filter: true,
        filterType: 'contains',
      },
      {
        _t_id: 'dcb792d8',
        prop: 'callStatus/label',
        text: 'Call Status',
        sort: true,
        type: 'text',
        detail: false,
      },
      {
        _t_id: '08f999a8',
        prop: 'direction',
        text: 'Direction',
        sort: true,
        type: 'text',
        detail: false,
      },
    ]

    return {
      selectedRows: [],
      isAdmin: false,
      tableInit: {
        enableExport: false,
        enableExportOverride: false,
        exportServiceAction: (params) => calls.callsTableExport(params),
        exportName: 'calls.csv',
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        tableId:
          this.$props.mode === 'history'
            ? 'call_history_view'
            : 'calls_tv_view',
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        detail: CallViewDetail,
        detailKeyId: 'callId',
        collection: 'Calls',
        categories: categories,
        columns:
          this.$props.mode === 'history' ? historyColumns : defaultColumns,
        action: (params) => calls.getCalls(params),
      },
    }
  },
  computed: {
    ...authComputed,
    isModeHistory() {
      return this.mode === 'history'
    },
  },
  mounted() {
    if (this.$store.getters['auth/hasPermission']('canExportCalls')) {
      this.tableInit.enableExport = true
      this.tableInit.enableExportOverride = true
    }
    if (!this.isL1Manager) {
      this.tableInit.columns.splice(
        2,
        0,
        {
          _t_id: 'a9c6c7fc1',
          prop: 'phoneNumber/callerId',
          text: 'Brand',
          filter: true,
          sort: true,
          type: 'text',
          filterType: 'contains',
          filterAsIs: true,
        },
        {
          _t_id: 'b9c6c7fc1',
          prop: 'callTo',
          text: 'Brand Number',
          sort: true,
          filter: true,
          filterType: 'contains',
          type: 'text',
        }
      )
    }
  },
}
</script>
