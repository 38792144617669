<template>
  <audio v-if="recordingUrl" :id="audioPlayerId" controls>
    <source :src="fixedRecordingUrl" type="audio/wav" />
    <span>
      It appears your browser does not support audio playback.
      <a :href="recordingUrl">Click Here</a>
      to download call data.
    </span>
  </audio>
  <span v-else>No recording available for this call.</span>
</template>

<script>
export default {
  props: {
    recordingUrl: {
      type: String,
      required: true,
    },
    callId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    audioPlayerId() {
      return `call-audio-player-call-id-${this.callId}`
    },
    fixedRecordingUrl() {
      const url = this.recordingUrl.replace('.mp3', '.wav')
      return `${url}#t=00:00:05`
    },
  },
  mounted() {
    const audioPlayer = document.getElementById(this.audioPlayerId)
    audioPlayer.addEventListener('timeupdate', () => {
      if (audioPlayer.currentTime < 5) {
        audioPlayer.currentTime = 5
      }
    })
  },
}
</script>
