<template>
  <v-layout row wrap>
    <v-flex xs12>
      <template v-if="row.action === 'mark_call_returned'">
        <Confirm
          header="Call Returned"
          message="Would you like to mark this call as returned?"
          style="width: 60%; margin: 15px 0px"
          @cancel="closeCard"
          @confirm="confirmCallReturned"
        />
      </template>
      <template v-if="row.action === 'call_comments'">
        <CallComments
          style="width: 70%; margin: 15px 0"
          :call-comments="comments"
          @close="closeCard"
          @save="addNote"
          @update="updateComment"
        />
      </template>
      <template v-if="row.action === 'call_audio'">
        <CallAudioPlayer
          :recording-url="row.recordingUrl"
          :call-id="row.callId"
        />
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import Confirm from '@/components/Confirm.vue'
import calls from '@/services/calls'
import CallComments from './CallComments.vue'
import CallAudioPlayer from './CallAudioPlayer.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Confirm,
    CallComments,
    CallAudioPlayer,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
      call: undefined,
      comments: [],
    }
  },
  async mounted() {
    await this.getCallData()
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    closeCard() {
      this.props.expanded = false
    },
    async confirmCallReturned() {
      const response = await calls
        .markCallReturned(this.row?.callId)
        .catch((e) => e.response)
      if (response.status !== 200) {
        const message =
          response.data?.message ||
          'There was an error marking the call as returned.'
        return this.showAlert({
          message,
          type: 'error',
        })
      }
      this.$emit('refresh-query-request')
      this.closeCard()
    },
    async getCallData() {
      const callResponse = await calls.getCallById(this.row?.callId)
      this.call = callResponse?.data?.call
      this.comments = this.call.callNotes
    },
    async addNote(note) {
      const callId = this.row?.callId
      const payload = {
        callId,
        note,
        title: `Comment added for call Id ${callId}`,
      }
      await calls.addCallNote(payload)
      this.showAlert({
        message: 'Comment added',
      })
      this.closeCard()
    },
    async updateComment(comment) {
      await calls.editCallNote(comment)
      this.showAlert({
        message: 'Comment edited',
      })
      this.closeCard()
    },
  },
}
</script>

<style lang="scss">
.call-player {
  background-image: none !important;
  background: $primary;
  span {
    color: white !important;
  }
  .icon-stopcircle-fill {
    display: none !important;
  }

  .slider .process {
    background: $gray-medium-light !important;
  }
}
</style>
