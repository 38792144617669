<template>
  <Comment
    :comments="comments"
    :editable-comments="false"
    edit-key-pro="callId"
    :comment-author-handler="commentAuthor"
    @update="(comment) => $emit('update', comment)"
    @cancel="() => $emit('close')"
    @save="(note) => $emit('save', note)"
  />
</template>

<script>
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import Comment from '@/components/Comment.vue'

export default {
  components: {
    Comment,
  },
  props: {
    callComments: { type: Array, default: () => [] },
  },
  data() {
    return {
      note: '',
      validNote: true,
      formSubmitAttempt: false,
    }
  },
  computed: {
    comments() {
      const sortByDate = (a, b) =>
        DateTime.fromISO(a.createdOn) > DateTime.fromISO(b.createdOn) ? -1 : 0
      const commentsOut = deepClone(this.callComments)
      commentsOut.sort(sortByDate)
      return commentsOut
    },
  },
  methods: {
    commentAuthor(comment) {
      const { createdOn } = comment
      const commentDateTime = DateTime.fromISO(createdOn).toLocaleString(
        DateTime.DATETIME_SHORT
      )
      return `${commentDateTime} by ${comment?.createdBy?.firstName} ${comment?.createdBy?.lastName[0]}.`
    },
  },
}
</script>
